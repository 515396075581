import { render, staticRenderFns } from "./AliyunVideo.vue?vue&type=template&id=67f3552a&scoped=true"
import script from "./AliyunVideo.vue?vue&type=script&lang=js"
export * from "./AliyunVideo.vue?vue&type=script&lang=js"
import style0 from "./AliyunVideo.vue?vue&type=style&index=0&id=67f3552a&prod&scoped=true&lang=less"
import style1 from "./AliyunVideo.vue?vue&type=style&index=1&id=67f3552a&prod&lang=less"
import style2 from "./AliyunVideo.vue?vue&type=style&index=2&id=67f3552a&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f3552a",
  null
  
)

export default component.exports